@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.chart-wrapper canvas {
  width: 100%;
}

base-chart.chart {
  display: block;
}

canvas {
  user-select: none;
}

.chartjs-tooltip {
  position: absolute;
  z-index: 1021;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  color: #fff;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.25s ease;
  transform: translate(-50%, 0);
  border-radius: 0.25rem;
}
.chartjs-tooltip .tooltip-header {
  margin-bottom: 0.5rem;
}
.chartjs-tooltip .tooltip-header-item {
  font-size: 0.875rem;
  font-weight: 700;
}
.chartjs-tooltip .tooltip-body-item {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  white-space: nowrap;
}
.chartjs-tooltip .tooltip-body-item-color {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border: 2px solid #fff;
}
.chartjs-tooltip .tooltip-body-item-value {
  padding-left: 1rem;
  margin-left: auto;
  font-weight: 700;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-19 {
  width: 19%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-22 {
  width: 22%;
}

.w-23 {
  width: 23%;
}

.w-24 {
  width: 24%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-27 {
  width: 27%;
}

.w-28 {
  width: 28%;
}

.w-29 {
  width: 29%;
}

.w-30 {
  width: 30%;
}

.w-31 {
  width: 31%;
}

.w-32 {
  width: 32%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-35 {
  width: 35%;
}

.w-36 {
  width: 36%;
}

.w-37 {
  width: 37%;
}

.w-38 {
  width: 38%;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.w-41 {
  width: 41%;
}

.w-42 {
  width: 42%;
}

.w-43 {
  width: 43%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-47 {
  width: 47%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-51 {
  width: 51%;
}

.w-52 {
  width: 52%;
}

.w-53 {
  width: 53%;
}

.w-54 {
  width: 54%;
}

.w-55 {
  width: 55%;
}

.w-56 {
  width: 56%;
}

.w-57 {
  width: 57%;
}

.w-58 {
  width: 58%;
}

.w-59 {
  width: 59%;
}

.w-60 {
  width: 60%;
}

.w-61 {
  width: 61%;
}

.w-62 {
  width: 62%;
}

.w-63 {
  width: 63%;
}

.w-64 {
  width: 64%;
}

.w-65 {
  width: 65%;
}

.w-66 {
  width: 66%;
}

.w-67 {
  width: 67%;
}

.w-68 {
  width: 68%;
}

.w-69 {
  width: 69%;
}

.w-70 {
  width: 70%;
}

.w-71 {
  width: 71%;
}

.w-72 {
  width: 72%;
}

.w-73 {
  width: 73%;
}

.w-74 {
  width: 74%;
}

.w-75 {
  width: 75%;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}

.w-78 {
  width: 78%;
}

.w-79 {
  width: 79%;
}

.w-80 {
  width: 80%;
}

.w-81 {
  width: 81%;
}

.w-82 {
  width: 82%;
}

.w-83 {
  width: 83%;
}

.w-84 {
  width: 84%;
}

.w-85 {
  width: 85%;
}

.w-86 {
  width: 86%;
}

.w-87 {
  width: 87%;
}

.w-88 {
  width: 88%;
}

.w-89 {
  width: 89%;
}

.w-90 {
  width: 90%;
}

.w-91 {
  width: 91%;
}

.w-92 {
  width: 92%;
}

.w-93 {
  width: 93%;
}

.w-94 {
  width: 94%;
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.w-99 {
  width: 99%;
}

.w-100 {
  width: 100%;
}

html {
  --primaryBg: #f8f8fb;
  --secondaryBg: #fff;
  --tertiaryBg: #f2f2f2;
  --disabledInputBg: #e9ecef;
  --navTextClr: #333;
  --navActiveTextClr: #333;
  --textDarkClass: #343a40;
  --textSecondaryClass: #6c757d;
  --primaryText:#212529;
  --secondaryText:#212529;
  --borderColor: #ced4da;
  --accordianBorder: #a2aab266;
  --accordianActive: #f5f5f5;
  --accordianActiveBorder: blue;
  --activatedOption: #f5faff;
  --cardBackground: #fff;
  --cardHeader: #f7f7f7;
  --constantGray: #979797;
}

html[data-theme=dark] {
  --primaryBg: #121212;
  --secondaryBg: #2a2a2a;
  --tertiaryBg: #454141;
  --disabledInputBg: #484c51;
  --navTextClr: #333;
  --navActiveTextClr: #fff;
  --textDarkClass: #f8f8f8;
  --textSecondaryClass: #c1c2c2;
  --primaryText:#d4dbdf;
  --secondaryText:#d0d4d7;
  --borderColor: #57595b;
  --accordianBorder: #57595b;
  --accordianActive: #1e1b1b;
  --accordianActiveBorder: #9a13ef;
  --activatedOption: #4f5255;
  --cardBackground: #1d1d1d;
  --cardHeader: #2e2c2c;
  --constantGray: #979797;
}

.text-dark {
  color: var(--textDarkClass) !important;
}

.text-secondary {
  color: var(--textSecondaryClass) !important;
}

.text-muted {
  color: var(--textSecondaryClass) !important;
}

.border {
  border: 1px solid var(--borderColor) !important;
}

.table {
  color: var(--secondaryText) !important;
}
.table td, .table th {
  border-color: var(--borderColor) !important;
}
.table tbody + tbody {
  border: none;
}

.decoration-underline {
  text-decoration: underline;
}

.modal-content {
  background-color: var(--cardBackground) !important;
}

.list-group-item {
  background: var(--cardBackground);
  color: var(--textSecondaryClass);
}

.list-group-item-action:focus, .list-group-item-action:hover {
  background: var(--secondaryBg) !important;
  color: var(--primaryText) !important;
}

.list-group-item.active {
  background: var(--secondaryBg) !important;
  color: var(--primaryText) !important;
}

a:hover {
  color: var(--primaryText);
}

.page-link {
  background-color: var(--secondaryBg);
}

body {
  overflow-x: hidden;
  padding-right: 0px !important;
  background: var(--primaryBg) !important;
  color: var(--primaryText) !important;
  font-family: "Open Sans", sans-serif !important;
  overflow-y: hidden;
}

.mat-expansion-panel {
  background: var(--secondaryBg) !important;
}

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: var(--primaryText) !important;
}

@media (max-width: 768px) {
  body {
    overflow-y: auto;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #969696;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #007bff;
}

a {
  color: var(--navTextClr);
  text-decoration: none !important;
}

header {
  background: #5245b5;
  padding: 0 !important;
  position: relative;
}

header h5 {
  color: #fff;
  margin: 0;
}

section {
  width: 100%;
  max-width: 1680px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 992px) {
  section {
    max-width: calc(100vw - 4%);
  }
}
@media print {
  .noprint {
    display: none;
  }
}
.logo-bg {
  background: #4033a5;
}

.card {
  background: var(--cardBackground);
  border: 0 !important;
}

.card-header {
  background-color: var(--cardHeader);
}

label {
  font-family: "Arial Regular";
  margin: 0;
}

.text-danger {
  color: #ff0e0e !important;
}

.textPrimaryColor {
  color: #5245b5 !important;
}

.d-grid {
  display: grid;
}

.d-grid {
  display: grid !important;
}

.header-right-bg {
  height: 90px;
  width: 45px;
  border-bottom-left-radius: 90px;
  border-top-left-radius: 90px;
  background: #20b7fa;
  position: absolute;
  right: -5px;
  top: -50px;
}

.navMain {
  background: 0 0 !important;
  background-color: transparent;
}

.navMain a.nav-link {
  color: #a2a2a2;
}

.navMain .nav-item > .nav-link.active {
  color: var(--navActiveTextClr);
  font-weight: 800;
}

.navMain .navbar-nav .nav-link {
  position: relative;
  font-weight: 400 !important;
}

.navMain .navbar-nav .nav-link.active::after,
.navMain .navbar-nav .nav-link:hover::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  bottom: -4px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #5245b5 transparent;
}

.titleTag {
  font-weight: 600 !important;
  position: relative;
  display: inline-block;
  letter-spacing: 1px !important;
  color: var(--primaryText);
  font-family: "Arial Medium" !important;
}

.titleTag::after {
  content: "";
  width: 50px;
  height: 3px;
  position: absolute;
  left: 2%;
  bottom: -35%;
  background: #37a4f3;
}

.topUserprofile a.nav-link {
  color: #fff;
  font-weight: 600;
}

.topUserprofile li {
  list-style: none;
}

.userProfileCirle {
  background-color: #1edede;
  min-width: 0;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  line-height: 32px;
  border-radius: 50%;
  margin-right: 80px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: "Arial Regular";
}

.page-link {
  color: #5245b5 !important;
  margin: 0 3px;
  border: 1px solid #5245b5 !important;
  border-radius: 3px !important;
  padding: 0.2rem 0.5rem;
}

.page-item.active .page-link {
  background-color: #5245b5 !important;
  border-color: #5245b5 !important;
  color: #fff !important;
}

.container-wrapper {
  margin-top: 100px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .container-wrapper {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px;
  }
}
.btn {
  padding: 0.175rem 0.975rem;
}

.btn:disabled {
  cursor: not-allowed;
}

.btn:focus {
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #37a4f3;
  border-color: #37a4f3;
  border-radius: 5px !important;
}

.btn-secondary {
  color: #fff;
  background-color: #5245b5;
  border-color: #5245b5;
  border-radius: 5px !important;
}

.btn-success {
  color: #fff;
  background-color: #00ca99;
  border-color: #00ca99;
  border-radius: 5px !important;
}
.btn-success:focus, .btn-success :active, .btn-success :hover {
  color: #fff;
  background-color: #32bd8b;
  border-color: #35b884;
}

.btn-outline-success {
  color: #00ca99;
  background-color: transparent;
  border-color: #00ca99;
  border-radius: 5px !important;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00ca99;
  border-color: #00ca99;
}

.btn-outline-primary {
  color: #37a4f3;
  background-color: transparent;
  background-image: none;
  border-color: #37a4f3;
  border-radius: 5px !important;
}

.btn-rouder-primary {
  color: #fff;
  background-color: #00ca99;
  border-color: #00ca99;
  border-radius: 50px;
  padding: 0.375rem 1.5rem;
  font-family: Arial !important;
}

.btn-lg {
  padding: 0.5rem 2rem !important;
  line-height: 1.5 !important;
  border-radius: 5px !important;
}

.bg-gray {
  background: #f5f6fa;
}

.text-header {
  font: normal normal normal 16px Open Sans;
  color: #434343;
}

.card-header {
  font-weight: 600;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  border-radius: 0;
  padding: 0;
}

.dropdown-item {
  color: #d5d5d5 !important;
  padding: 0.5rem 1rem !important;
}

.dropdown-menu.show {
  background: #1f2839;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background: #0f1620 !important;
  color: #d5d5d5 !important;
}

.topUserprofile .dropdown-menu {
  left: 0;
  transform: translateX(5%) !important;
  top: 53px !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1f2839;
  margin: 0 0 15px 0;
  position: absolute;
  top: -9px;
  right: 0;
}

.active-list-item {
  color: #3ea6ff !important;
  background-color: #38414d !important;
}

.testactive {
  color: #fff;
}

.list-group-item {
  padding: 0.85rem 1.25rem;
  border: 1px solid rgba(183, 173, 173, 0.13);
}

.cardtitleTag {
  position: relative;
  border-bottom: 1px solid var(--borderColor);
  min-height: 50px;
}

.cardtitleTag h1 {
  color: #232323;
  font-family: "Open Sans";
  line-height: 30px;
}

.cardtitleTag::after {
  content: "";
  width: 50px;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: -4%;
  background: #37a4f3;
}

.createBtn {
  color: #048aff;
  font-family: "Open Sans", sans-serif;
}

.smallBtnLink {
  color: #5245b5;
  font-family: "Open Sans", sans-serif;
}

.section-cardSubtitle {
  margin: 16px 0 0;
}

.section-cardSubtitle h3 {
  font-weight: 600;
  color: #434343;
  font-family: "Open Sans";
  padding-top: 5px;
}

.modelColor {
  border: 1px solid var(--borderColor) !important;
  max-height: 30vh;
  overflow-y: auto;
}

.modelColor ul {
  display: flex;
  flex-wrap: wrap;
}

.modelColor li {
  color: var(--secondaryText);
  list-style: none;
  margin: 0;
  padding: 12px 0px 8px 0px !important;
  flex: 1 0 calc(20% - 10px);
  box-sizing: border-box;
}

.modelColor li span.yellowCirlce {
  width: 18px;
  height: 18px;
  background: #ffdf88;
  border-radius: 50%;
  margin-right: 10px;
}

.modelColor li span.gradient-left {
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  width: 9px;
  height: 18px;
  background: #0b6fc7;
}

.modelColor li span.gradient-right {
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  width: 9px;
  height: 18px;
  background: #12b9f8;
  margin-right: 10px;
}

.modal-backdrop {
  width: 105vw;
  height: 105vh;
}

.modal-header {
  border-radius: 0;
  color: #fff !important;
  background: #29235b;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  color: #fff;
  box-shadow: none;
  opacity: 1;
}

.modal-content {
  border-radius: 0;
  border: 1px solid #afafaf;
}

.modal-header .close:focus {
  outline: 0;
}

.modal-footer {
  border: 0;
}

.form-control-md {
  height: calc(1.5em + .75rem + 3px) !important;
}

.accordion,
.priceList {
  margin: 0;
  /*border-top   :1px solid #d9e5e8;padding:0;*/
  overflow: auto;
  font-family: "Arial Regular";
}

.accordion li,
.priceList li {
  border: 1px solid #d9e5e8;
  position: relative;
  list-style: none;
  border-top: 0;
  padding: 0 5px;
}

.accordion li:hover,
.priceList li:hover {
  background: #d9e5e8;
}

.accordion li .accorExpand {
  display: none;
  padding: 0;
  color: #6b97a4;
}

.accordion li,
.priceList li {
  width: 100%;
  display: inline-flex;
  line-height: 2;
  text-indent: 15px;
  user-select: none;
  text-decoration: none;
}

.accordion li:after {
  width: 8px;
  height: 8px;
  border-right: 1px solid #5245b5;
  border-bottom: 1px solid #5245b5;
  position: absolute;
  right: 20px;
  content: " ";
  top: 50%;
  transform: rotate(45deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.accordion .accorExpand {
  line-height: 2;
  padding: 10px;
  display: block;
  width: 100%;
}

.accordion li.active:after {
  transform: rotate(-135deg) !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 8px;
  height: 8px;
  border-right: 1px solid #5245b5;
  border-bottom: 1px solid #5245b5;
  position: absolute;
  right: 20px;
  content: " ";
  top: 25%;
}

.accordion li.active {
  border-right: 1px solid #d9e5e8;
  border-top: 3px solid #5245b5;
  background: #f6f6f6;
  border-left: 1px solid #d9e5e8;
}

.accordion li small,
.accordion small,
.priceList li small,
.priceList small {
  display: block;
  padding-top: 8px;
  color: #9c9a9a;
  line-height: 13px;
}

.accordion li label,
.priceList li label {
  color: var(--navTextClr);
  font-weight: 600;
}

.accordion li .fa,
.priceList li .fa {
  display: initial !important;
  color: #5245b5;
}

.priceList li:after {
  display: none;
}

.modal-fullscreen-xl {
  padding: 0 !important;
  margin-top: 55px;
}

.modal-fullscreen-xl .modal-dialog {
  width: 100%;
  max-width: none;
  height: 90%;
  margin: 0;
}

.modal-fullscreen-xl .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen-xl .modal-body {
  overflow-y: auto;
}

.modal-fullscreen-xl .modal-header {
  background: #2d2e5a !important;
  color: #fff;
  border-radius: 0;
}

.modal-fullscreen-xl .modal-header .close {
  color: #fff;
  opacity: 1;
  outline: 0;
}

.modal-fullscreen-xl .modal-header .close:hover {
  color: #1edede;
  opacity: 1;
}

.btn-open-modal {
  margin-bottom: 0.5em;
}

.modal-fullscreen-xl .custom-control-input,
.modal-fullscreen-xl .custom-control-label::after,
.modal-fullscreen-xl .custom-control-label::before {
  width: 1.3rem;
  height: 1.3rem;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: none;
}

.modal-fullscreen-xl .card-body {
  padding: 0.5rem 1.25rem;
}

.vehicleBillingTbl td {
  width: auto;
}

.vehicleBillingTbl td span {
  float: left !important;
}

.table thead th {
  vertical-align: bottom;
  border: 0;
  background: var(--cardHeader);
  font-weight: 600;
}

.list-group-item.active {
  z-index: 2;
  border-color: var(--borderColor) !important;
}

.settings-header {
  background: var(--cardBackground);
  padding: 0px 12px 12px 12px;
}

.setting-wrapper.card.accordion {
  min-height: 46.1vh !important;
  max-height: 46.1vh !important;
  overflow-y: hidden;
}

.manageListview {
  margin: 0;
  padding: 0;
  list-style: none;
}

.manageListview a.list-group-item-action {
  position: relative;
  padding-left: 1.7rem;
}

.manageListview a.list-group-item-action::before {
  content: "•";
  color: #b8b8b8;
  position: absolute;
  left: 5%;
  height: 100%;
  top: auto;
}

/* .manageListview a.list-group-item-action.active{background:#000} */
.manageListview a.list-group-item-action.active::before {
  color: #5245B5;
}

.input-group-prepend {
  background-color: var(--secondaryBg);
}
.input-group-prepend .input-group-text {
  border-color: var(--borderColor);
}

.delIcon {
  background: var(--tertiaryBg);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}

.input-group-text {
  padding: 0rem 0.5rem !important;
  background: 0 0;
  border-right: 0;
}

.inputGroupSymbol {
  border-left: 1px solid transparent !important;
}

.varentColor {
  display: flex;
  flex-wrap: wrap;
}

.varentColor {
  color: #43425d;
  list-style: none;
  margin: 0;
  padding: 0 0 10px;
  flex: 1 0 calc(20% - 10px);
  box-sizing: border-box;
  font-weight: 600;
}

.varentColor span.yellowCirlce {
  width: 10px;
  height: 10px;
  background: #ffdf88;
  border-radius: 50%;
  margin-right: 5px;
}

.varentColor span.gradient-left {
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  width: 9px;
  height: 18px;
  background: #0b6fc7;
}

.varentColor span.gradient-right {
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
  width: 9px;
  height: 18px;
  background: #12b9f8;
  margin-right: 10px;
}

.file-upload-button {
  outline: 0;
  background-color: #e8e8e8;
  height: 2rem;
  position: relative;
  border: 1px solid #ababab;
  margin-left: 10px;
  width: 6rem;
}

input[type=file] {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.file-info {
  color: #999;
  margin-left: 0;
  border: 1px solid #d9d9d9;
  height: 33px;
  border-radius: 5px;
}

.trashIcon .facircle {
  background: var(--tertiaryBg);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 22px;
  position: relative;
}

.trashIcon .facircle .fa {
  position: absolute;
  left: -45%;
  top: 14%;
}

.cardBorder {
  border: 1px solid #dcd9d9;
  border-radius: 5px;
}

.text-active {
  color: #539c14 !important;
}

.priceListModel,
.priceListModel li {
  border: none !important;
}

.cardBorder small {
  color: #9C9A9A;
}

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Arial Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Regular"), url(assets/fonts/ARIAL.woff) format("woff");
}
@font-face {
  font-family: "Arial Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Bold"), url(assets/fonts/ARIALI-BOLD.woff) format("woff");
}
@font-face {
  font-family: "Arial Medium";
  font-style: normal;
  font-weight: 400;
  src: local("Arial Medium"), url(assets/fonts/ArialMdm.woff) format("woff");
}
.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
  pointer-events: none;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.cursor-pointer,
select {
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0px;
  margin-right: 2rem;
}

select.form-control {
  padding: 0.1rem 1.3rem 0.1rem 0.55rem !important;
}

.fa-external-link,
.fa-file-pdf-o,
.fa-download {
  color: #4033a5;
  cursor: pointer;
}

.fa-external-link:hover,
.fa-file-pdf-o:hover,
.fa-download:hover {
  color: #4033a5;
  transform: scale(1.2);
}

.btn {
  cursor: pointer;
}

.accordion small {
  display: block;
  padding-top: 8px;
  color: #9c9a9a;
  line-height: 13px;
}

.accordion label {
  color: var(--primaryText);
  font-weight: 600;
}

.accordion {
  justify-content: space-between;
}

.border-top {
  border-top: 2px solid var(--accordianActiveBorder) !important;
  background: var(--activatedOption);
  margin: 0px 0px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--disabledInputBg) !important;
}

.form-control {
  background-color: var(--secondaryBg) !important;
  color: var(--secondaryText) !important;
  border-color: var(--borderColor) !important;
}
.form-control:focus {
  color: var(--secondaryText) !important;
}

.form-select.is-invalid,
.form-select.is-valid {
  background-position: right calc(0.975em + 0.1875rem) center !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + .75rem) !important;
}

input[type=date].form-control.is-invalid,
input[type=datetime-local].form-control.is-invalid,
input[type=month].form-control.is-invalid,
input[type=time].form-control.is-invalid {
  padding: 0.375rem 1.5rem 0.375rem 0.55rem !important;
}

input[type=date].form-control.is-valid,
input[type=datetime-local].form-control.is-valid,
input[type=month].form-control.is-valid,
input[type=time].form-control.is-valid {
  padding: 0.375rem 1.5rem 0.375rem 0.55rem !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}

.form-control {
  font-family: "Open Sans", sans-serif !important;
  height: calc(1em + 0.75rem + 3px);
  padding: 0.1rem 0.75rem 0.1rem 0.55rem !important;
}

.ellipse {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.toast-container {
  font-size: 14px !important;
}
.toast-container .ngx-toastr {
  border-radius: 5px !important;
  box-shadow: none !important;
}
.toast-container .ngx-toastr:hover {
  box-shadow: none !important;
}
.toast-container button.toast-close-button {
  top: 0 !important;
}
.toast-container .success-toastr {
  background-color: #29714bf2;
  border: 2px solid #09be5d;
  padding: 10px 15px 10px 50px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
.toast-container .error-toastr {
  background-color: #ff010187;
  border: 2px solid #f15252;
  padding: 10px 15px 10px 50px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
.toast-container .wanring-toastr {
  background-color: #ca4116d1;
  border: 2px solid #ff5621;
  padding: 10px 15px 10px 50px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container .information-toastr {
  background-color: #29609ec4;
  border: 2px solid #2975cc;
  padding: 10px 15px 10px 50px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

@media (min-width: 992px) {
  ::ng-deep .toast-container.toast-top-center .ngx-toastr {
    width: 350px !important;
  }
}
.cdk-global-overlay-wrapper {
  z-index: 1000;
}

.cdk-overlay-container {
  z-index: 2000;
}

.modal-title {
  font-weight: 500;
}

.facircle,
.fa-trash,
.fa-save,
.fa-edit {
  cursor: pointer;
}

.delete .btn {
  height: 30px;
  width: 100px;
  font-family: arial;
  color: #fff;
  font-weight: 800;
}

.setting-wrapper .table-responsive {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.setting-wrapper thead {
  flex: 0 0 auto;
  width: 100%;
}

.setting-wrapper tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.setting-wrapper .table,
.setting-wrapper tbody {
  max-height: calc(100vh - 308px);
  overflow-y: auto;
  width: 100%;
}

.ulTable .setting-wrapper .table {
  max-height: calc(100vh - 354px) !important;
}

.setting-modal-body .setting-wrapper {
  overflow-y: hidden !important;
}

.inr-input {
  width: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20px' width='15px'><text x='3' y='17' fill='rgb(73, 80, 87)' font-size='13' font-family='arial'>₹</text></svg>");
  padding-left: 12px !important;
  background-repeat: no-repeat;
}

.modal-list table {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.modal-list thead {
  flex: 0 0 auto;
}

.modal-list tbody {
  min-height: 0vh;
  max-height: 33vh;
  overflow-y: auto;
}

.modal-list tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.modal-list th {
  padding: 10px 20px;
  text-align: center;
}

.mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.accordion small {
  display: block;
  padding-top: 8px;
  color: #9c9a9a;
  line-height: 13px;
}

.accordion {
  justify-content: space-between;
  overflow-x: hidden;
}

.mat-expansion-panel-body {
  margin-right: 15px !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
  background: var(--accordianActive) !important;
  border-top: 2px solid var(--accordianActiveBorder) !important;
}

.mat-accordion-table {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: hidden;
}

.mat-expansion-panel {
  border: 1px solid;
  border-color: var(--accordianBorder);
}

.modal-open .modal {
  overflow-y: hidden !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.modal-footer {
  border-top: 1px solid #ece1e1;
}

.close-cross {
  outline: none !important;
}

.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #969696;
}

.custom-ng-select .ng-dropdown-panel.ng-select-bottom {
  margin-top: 3px !important;
}
.custom-ng-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #6cadf5 !important;
  color: #fff !important;
  border-radius: 3px;
}
.custom-ng-select .form-control {
  padding: 0px !important;
}
.custom-ng-select .form-control.ng-select-disabled {
  background: #e9ecef !important;
}
.custom-ng-select .ng-select .ng-select-container .ng-value-container {
  padding-left: 5px !important;
}
.custom-ng-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #495057 !important;
}
.custom-ng-select .ng-select.ng-select-single.ng-select-disabled .ng-select-container {
  background: #e9ecef !important;
}
.custom-ng-select .ng-select.ng-select-single .ng-select-container {
  height: 24px !important;
  min-width: 150px !important;
}
.custom-ng-select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  width: 100% !important;
  position: relative;
}
.custom-ng-select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .icon {
  top: 1px !important;
}
.custom-ng-select .ng-select.ng-select-multiple .ng-select-container {
  height: 24px !important;
}
.custom-ng-select .ng-select .ng-select-container {
  min-height: 24px !important;
  border: none !important;
  background-color: var(--secondaryBg) !important;
  color: var(--secondaryText) !important;
}
.custom-ng-select .ng-dropdown-panel {
  z-index: 1025 !important;
}
.custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 1px 10px !important;
}
.custom-ng-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0px;
  padding-bottom: 5px;
}
.custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: var(--secondaryBg) !important;
  color: var(--secondaryText) !important;
}
.custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--activatedOption) !important;
}
.custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: var(--activatedOption) !important;
}
.custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: var(--secondaryBg) !important;
}
.custom-ng-select .ng-select .ng-clear-wrapper {
  display: none;
}
.custom-ng-select .ng-dropdown-footer {
  text-align: right !important;
}
.custom-ng-select .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 0px;
  border-color: #999 transparent transparent;
  border-width: 5px 5px 2.5px;
}

.invalid-feedback {
  text-align: left;
}
.invalid-feedback p {
  margin-left: 3px;
}

.inventory .container-wrapper,
.dashboard .container-wrapper,
.tasks .container-wrapper,
.settings .container-wrapper {
  margin-top: 80px;
  position: relative;
}

.sub-menu {
  margin-left: 0px;
  background-color: var(--secondaryBg);
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
  position: fixed !important;
  z-index: 100 !important;
  left: 0 !important;
  right: 0 !important;
}
.sub-menu .navbar {
  padding-bottom: 0px;
}
.sub-menu .navbar .navbar-nav {
  padding-top: 5px;
}
.sub-menu .navbar .nav-item {
  padding: 0 10px;
  text-align: center;
}
.sub-menu .navMain .navbar-nav .nav-link.active::after,
.sub-menu .navMain .navbar-nav .nav-link:hover::after {
  content: "";
  position: absolute;
  left: 1px;
  margin-left: 0px;
  width: 98%;
  height: 0px;
  background: #5245B5 0% 0% no-repeat padding-box;
  opacity: 1;
  bottom: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #5245b5;
}

.switch {
  position: relative;
  display: inline-block;
  /*------ ADDED CSS ---------*/
  /*--------- END --------*/
  /* Rounded sliders */
}
.switch input {
  display: none;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 75%;
  width: 50%;
  left: 3px;
  bottom: 3px;
  z-index: 2;
  border-radius: 5px;
  transition: 0.4s;
}
.switch .switch-text {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-weight: 700;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}

.dashbord-card-title {
  font-weight: 800;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dashboard-card {
  border: 1px solid var(--borderColor) !important;
}

@media only screen and (max-width: 768px) {
  .sub-menu .navbar-expand-sm {
    justify-content: center;
  }
}
.sidenavButton div {
  position: fixed;
  transition: 0.3s;
  padding: 5px 10px;
  top: 30%;
  text-decoration: none;
  color: white;
  border-radius: 0 5px 5px 0;
  z-index: 2;
}

.sidenavButton div:hover {
  left: 0;
}

.setting-modal-body {
  min-height: 278px;
  height: calc(100vh - 270px);
  overflow-y: auto;
}

.setting-modal-body.accordion {
  min-height: 278px;
  height: calc(100vh - 348px);
  overflow-y: auto;
}

@media (min-width: 770px) and (max-width: 1300px) {
  .setting-modal-body.accordion {
    min-height: 250px;
    height: calc(100vh - 370px);
  }
}
@media (max-width: 768px) {
  .setting-modal-body.accordion {
    min-height: 278px;
    height: calc(100vh - 330px);
    overflow-y: auto;
  }
}
.tooltip-text .popover {
  background-color: #0000006e !important;
  border: none;
}
.tooltip-text .bs-popover-auto[x-placement^=right] > .arrow::before,
.tooltip-text .bs-popover-right > .arrow::before {
  border-right-color: #0000006e;
}
.tooltip-text .bs-popover-right > .arrow::after {
  border-right-color: #0000006e;
}
.tooltip-text .popover-body {
  color: #fff;
  background-color: #0000006e;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
}