// --------------Global font size controller Start---- Large screen (above 21 inch)--------------

@media screen and (min-width: 1700px) {

    .main-header-company-name {
        font-size: 16px !important;
    }

    .main-header-profile-name {
        font-size: 15px !important;
    }

    .sub-header-main-menu {
        font-size: 14px !important;
    }

    .sub-header-sub-menu {
        font-size: 12px !important;
    }

    .input-label {
        font-size: 12px !important;
    }

    .input-error-msg {
        font-size: 12px;
        color    : red;
    }

    .form-group {
        margin-bottom: .75rem;
    }

    .btn {
        font-size: 14px;
    }

    .table {
        margin-bottom: 5px !important;
    }

    // Pagination

    .page-link {
        font-size: 14px;
    }

    .switch {
        .switch-text {
            font-size: 11px;
        }
    }

    .accordion {
        label {
            font-size: 12px;
        }

        small {
            font-size: 11px;
        }
    }

    .invalid-feedback {
        font-size: 10px;
    }

    //------ Dashboard --------

    .dashbord-card-title {
        font-size: 13px;
    }

    .dashboard-chart-legends {
        font-size: 11px;
    }

    .dashboard-table-data-legend {
        font-size: 11px;
    }

    .table thead th {
        font-size: 13px !important;
    }

    .table td {
        font-size: 12px !important;
    }

    tbody th,
    tfoot th {
        font-size: 12px !important;
    }

    .card-menu {
        .navMain .navbar-nav .nav-link {
            font-size: 12px;
        }
    }

    .direction-arrow {
        width : 13px;
        height: 13px;
    }

    .direction-arrow-equal {
        width : 20px;
        height: 20px;
    }

    // Progress Bar Styles 

    .progress-xl {
        height: 10px;
    }

    //------ Settings ---------
    .side-menu-header {
        font-size: 14px !important;
    }

    .side-menu {
        font-size: 14px !important;
    }

    .page-title-header {
        font-size: 18px !important;
    }

    .table-header,
    .content-header {
        font-size: 14px !important;
    }

    .table-body-content,
    .body-content {
        font-size: 14px !important;
    }

    .custom-card-header {
        font-size: 14px !important;
    }

    .custom-card-body {
        font-size: 14px !important;
    }

    .create-new,
    .edit-btn,
    .delete-btn {
        font-size: 14px !important;
    }

    .fa-plus,
    .fa-edit,
    .fa-pencil,
    .fa-trash,
    .icon {
        font-size: 14px !important;
    }

    .form-control {
        font-size: 12px !important;
    }

    .modal-header {
        padding: 15px 15px;
    }

    .modal-title {
        font-size: 16px !important;
    }

    .card-sec-header {
        font-size: 13px;
    }

    // .setting-modal-body {
    //     min-height: 20vh;
    //     max-height: 60vh;
    //     overflow-y: auto
    // }

    .login-title {
        font-size: 35px;
    }

    .login-company-logo {
        height: 90px;
        width : 175px;
    }

    // For settings small fonts size
    .setting-modal-body {
        small {
            font-size: 11px;
        }

        .accordion {
            label {
                font-size: 12px;
            }

            small {
                font-size: 11px;
            }
        }
    }
}

// --------------Global font size controller End------------------


// --------------Global font size controller Start---- Large screen (below 17 inch)--------------

@media screen and (max-width: 1680px) {

    .main-header-company-name {
        font-size: 14px !important;
    }

    .main-header-profile-name {
        font-size: 14px !important;
    }

    .sub-header-main-menu {
        font-size: 12px !important;
    }

    .sub-header-sub-menu {
        font-size: 11px !important;
    }

    .input-label {
        font-size: 10px !important;
    }

    .input-error-msg {
        font-size: 10px;
        color    : red;
    }

    .form-group {
        margin-bottom: .5rem !important;
    }

    .table {
        margin-bottom: 5px !important;
    }

    .switch {
        .switch-text {
            font-size: 10px;
        }
    }

    .fa-plus,
    .fa-edit,
    .fa-pencil,
    .fa-trash,
    .icon {
        font-size: 13px !important;
    }

    // Pagination

    .page-link {
        font-size: 11px;
    }

    .accordion {
        label {
            font-size: 12px;
        }

        small {
            font-size: 11px;
        }
    }

    .invalid-feedback {
        font-size: 9px;
    }

    // Progress Bar Styles 

    .progress-xl {
        height: 6px;
    }

    .btn {
        font-size: 12px;
    }

    .table {
        margin-bottom: 5px !important;
    }

    .sub-menu {

        .navMain .navbar-nav .nav-link.active::after,
        .navMain .navbar-nav .nav-link:hover::after {
            border-width: .5px !important;
        }
    }

    //------ Dashboard --------

    .dashbord-card-title {
        font-size: 12px;
    }

    .dashboard-chart-legends {
        font-size: 10px;
    }

    .dashboard-table-data-legend {
        font-size: 10px;
    }

    .table thead th {
        font-size: 12px !important;
    }

    .table td {
        font-size: 11px !important;
    }

    tbody th,
    tfoot th {
        font-size: 11px !important;
    }

    .card-menu {
        .navMain .navbar-nav .nav-link {
            font-size: 11px;
        }
    }

    .direction-arrow {
        width : 12px;
        height: 12px;
    }

    .direction-arrow-equal {
        width : 18px;
        height: 18px;
    }

    .pie-chart {
        .legend-color {
            height    : 4px !important;
            margin-top: 7px !important;
            width     : 12px !important;
        }
    }

    //------ Settings---------
    .side-menu-header {
        font-size: 12px !important;
    }

    .side-menu {
        font-size: 12px !important;
    }

    .page-title-header {
        font-size: 16px !important;
    }

    .titleTag::after {
        height: 2px;
    }

    .table-header,
    .content-header {
        font-size: 14px !important;
    }

    .table-body-content,
    .body-content {
        font-size: 12px !important;
    }

    .custom-card-header {
        font-size: 13px !important;
    }

    .custom-card-body {
        font-size: 12px !important;
    }

    .create-new,
    .edit-btn,
    .delete-btn {
        font-size: 11px !important;
    }

    .fa-plus,
    .fa-edit,
    .fa-pencil,
    .fa-trash,
    .icon-note {
        font-size: 11px !important;
    }

    .form-control {
        font-size: 11px !important;
    }

    .custom-ng-select {

        .ng-select.ng-select-single .ng-select-container {
            height: 24px !important;
        }

        .ng-select.ng-select-multiple .ng-select-container {
            height: 24px !important;
        }

        .ng-select .ng-select-container {
            min-height: 24px !important;
        }
    }

    .modal-header {
        padding: 12px 12px;
    }

    .modal-title {
        font-size: 14px !important;
    }

    .card-sec-header {
        font-size: 12px;
    }



    .settings-menu-list {
        position  : absolute;
        min-height: 56.5vh;
        max-height: 72.5vh;
        overflow-y: scroll;
    }

    .login-title {
        font-size: 25px;
    }

    .login-company-logo {
        height: 90px;
        width : 175px;
    }

    .login-card-vw {
        width: 25vw;
    }

    // For settings small fonts size
    .setting-modal-body {
        small {
            font-size: 10px;
        }

        .accordion {
            label {
                font-size: 11px;
            }

            small {
                font-size: 10px;
            }
        }
    }
}

// --------------Global font size controller Start---- Large screen (below 15 inch)--------------

@media screen and (max-width: 1200px) {

    //------ Dashboard --------

    .dashbord-card-title {
        font-size: 12px;
    }

}

@media screen and (max-width : 991px) {

    .mat-expansion-panel-header {
        align-items: self-start !important;
        height     : 45px !important
    }

    .mat-expansion-panel-header.mat-expanded {
        height: auto !important;
    }

}

@media screen and (min-width : 992px) {

    .mat-expansion-panel-header {
        align-items: self-start !important;
        height     : 60px !important
    }
}



.uppercase {
    text-transform: uppercase;
}

// placeholder style

::-webkit-input-placeholder {
    text-transform: initial;
    padding-left  : 5px !important;
}

:-moz-placeholder {
    text-transform: initial;
    padding-left  : 5px;
}

::-moz-placeholder {
    text-transform: initial;
    padding-left  : 5px;
}

:-ms-input-placeholder {
    text-transform: initial;
    padding-left  : 5px;

}


.titlecase {
    text-transform: capitalize;
}

.text-ellipse {
    white-space  : nowrap;
    width        : 95%;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.page-content-container {
    padding-top: 60px !important;
}

.nillData {
    text-align: center;
    color     : #b5b3b3;
}

.refresh-btn{
    background: #5245b5 !important;
      border-color: #5245b5 !important;
  }

// .custom-multi-select.custom-ng-select{
//     min-width: 210px !important;
// }
// // Submenu header top for sm devices
// @media screen and (max-width: 426px) {
//     .sub-menu {
//         top: 100px !important;
//     }
// }