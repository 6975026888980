// ==============================
// Font size mixin
// ==============================

//  default font scale bounding
$font-lock-min:  400px !default; 
$font-lock-max: 1000px !default;

//  font-size 
@mixin font-size(
  $min,                         // font-size 
  $max: null,                   // optional maximum font size
  $lock-min: $font-lock-min,  // optional minimum viewport width 
  $lock-max: $font-lock-max   // optional maximum viewport width 
  ){
    & { font-size: $min !important; }
    @if $max {
      @media (min-width: $lock-min) and (max-width: $lock-max){ 
        & { 
          font-size: calc(
            #{$min} + 
            #{num($max) - num($min)} * 
            (100vw - #{$lock-min}) / 
            #{num($lock-max) - num($lock-min)}
          ) !important; 
        } 
      }
      @media (min-width: $lock-max){ 
        & { font-size: $max !important; } 
      }
    }
}

//  strip units helper function
@function num($input){  @return $input/($input * 0 + 1); }

$w-end: 100;

@mixin width {

  @for $i from 1 through $w-end {

      .w-#{$i} { width: #{$i}+'%' }

  }

}

@include width;